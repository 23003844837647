import React from 'react';
import { NavLink } from "react-router-dom";
function Header() {
    return (
        <section className="group-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-2 col-sm-3 col-xs-6 clearfix">
                        <NavLink to="/" className="flex items-center hover:text-yellow-800 ">
                            <img className="layer-2" src="/images/layer_2.png" alt="" />
                        </NavLink>
                    </div>
                    <div className="col-md-8 col-xs-12 clearfix">
                        <ul className="menu clearfix">
                            <li>
                                <p className="who-we-are">
                                    <a href="#"><NavLink to="/aboutUs" className="flex items-center hover:text-yellow-800 dropdown-toggle"
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        Who we are
                                    </NavLink></a>
                                </p>
                            </li>
                            <li>
                                <p className="what-we-do">
                                    <a href="#"><NavLink to="/expertise" className="flex items-center hover:text-yellow-800 dropdown-toggle"
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        What we do
                                    </NavLink></a>
                                </p>
                            </li>
                            <li>
                                <p className="our-impact">
                                    <a href="#"><NavLink to="/impact" className="flex items-center hover:text-yellow-800 dropdown-toggle"
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        Our Impact
                                    </NavLink></a>
                                </p>
                            </li>
                            <li>
                                <p className="careers">
                                    <a href="#"><NavLink to="/career" className="flex items-center hover:text-yellow-800 dropdown-toggle"
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        Careers
                                    </NavLink></a>
                                </p>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="col-md-2 col-sm-3 col-xs-6">
                        <div className="col-data-wrapper no-space-between-inline-blocks">
                            <img className="search-bar" src="images/search_bar.png" alt="" />
                            <div className="side-bar">
                                <div className="rectangle-3" />
                                <div className="rectangle-3-copy" />
                                <div className="rectangle-3-copy-2" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>

    );
}

export default Header;