import React from 'react';
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
function Footer() {
    const location = useLocation();
    console.log("location", location.pathname);
    // Determine class based on current route
    const getClassName = () => {
        switch (location.pathname) {
            case "/career":
                return "shape-3-bottom";
            default:
                return "shape-3";
        }
    };
    console.log("fixedAtBottom", getClassName());
    return (
        <section className={getClassName()}>
            <div className="container">
                <div className="row">
                    <div className="col-md-2 col-sm-3 col-xs-12">
                        <NavLink to="/" className="flex items-center hover:text-yellow-800 ">
                            <img className="logo" src="images/logo.png" alt="" />
                        </NavLink>
                    </div>
                    <div className="col-md-7 col-xs-12">
                        {/* <div className="text-27">
                            <div className="row match-height-bootstrap-row">
                                <div className="col-sm-6 col-xs-12">
                                    <div className="col-7">
                                        <p className="text-29">Candidate Privacy Notice</p>
                                        <p className="text-30">Latest Vacancies</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <div className="col-8">
                                        <p className="text-28">Talent Search Privacy Notice</p>
                                        <p className="terms">Terms</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <p className="text-32">Privacy Notice</p>
                                </div>
                                <div className="col-sm-4 col-xs-12 col-sm-offset-2">
                                    <p className="text-31">Fraud Alert</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="col-6">
                            <div className="social-media-icons no-space-between-inline-blocks clearfix">
                                <a href="https://m.facebook.com/thoughtgrainssolution/" target='_blank'><img
                                    className="facebook-app-symbol"
                                    src="/images/facebook-app-symbol.png"
                                    alt=""
                                /></a>
                                {/* <img className="twitter" src="images/twitter.png" alt="" /> */}
                                <a href="https://www.linkedin.com/in/thought-grains-91a416113?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target='_blank'><img className="linkedin" src="/images/linkedin.png" alt="" /></a>
                                {/* <img className="youtube" src="images/youtube.png" alt="" />
                                <img className="instagram" src="images/instagram.png" alt="" /> */}
                            </div>
                            <div className="text-33 clearfix">
                                {/* <p className="text-34">Cookies / Sitemap</p> */}
                                <p className="text-35">
                                    © Thought Grains 2025, all rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Footer;