import './../App.css';

function Impact() {

    return (
        <>
            <div className="container">
                <div>
                    <img src="/images/clients.png" loading="lazy" />
                </div>
                <br></br>
            </div>
        </>
    );
}

export default Impact;
