import './../App.css';

function Careers() {

    return (
        <>
            <div className="container careerClass">
                <br></br>

                <div className='contactClass'>
                    <h2>Contact: </h2>
                    <div>Thought Grains Business Avenue</div>
                    <div>S.No: 62/1, Shirva Road, Katapadi, Udupi,</div>
                    <div>Pincode - 574105, Karnataka, India</div>
                    <div>Info@thoughtgrains.com</div>
                </div>


                <br></br>
            </div>
        </>
    );
}

export default Careers;
