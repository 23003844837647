import './App.css';
import './additional.css';
import { useEffect } from 'react';
import Footer from './components/footer';
import Header from './components/header';
import Expertise from './pages/Expertise';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import { Routes, Route, useLocation } from "react-router-dom";
import Impact from './pages/Impact';
import Careers from './pages/Careers';

function App() {
  console.log("Viewport Width:", window.innerWidth);
  console.log("Viewport Height:", window.innerHeight);
  const { hash } = useLocation();

  // Scroll to the specified section
  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <>
      <div className="mainContainer">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/expertise/:id" element={<Expertise />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/impact" element={<Impact />} />
          <Route path="/career" element={<Careers />} />
          {/* <Route path="/career" element={<Job />} /> */}
        </Routes>
        <Footer />
      </div>

    </>
  );
}

export default App;
