import React from 'react';
function Section1() {
    return (
        <section className="image">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-xs-12 col-md-offset-1">
                        <div className="text">
                            <p className="welcome">Welcome</p>
                            <p className="text-2">to Thought Grains</p>
                            <p className="text-3">Where vision and creativity unite</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Section1;