import './../App.css';
import { useParams } from "react-router-dom";
import ExpertiseData from './../jsonData/expertiseData.json'

function Expertise() {
    return (
        <>
            <br></br>
            <div className='container'>
                <h1 className='aboutUs'>Our Expertise:</h1>
                <br></br>
                {ExpertiseData.map((item) => (
                    <>
                        <ul className='mainListType' id={"expert" + item.id}>
                            <li>
                                <h2>
                                    <b>{item.header}</b>
                                </h2>
                                <br></br>
                                <p className='text-justify'>{item.desc}</p>
                                <Leveldetails inDetail={item} />
                            </li>
                        </ul>
                        <br></br>
                    </>
                ))}
                <div>
                    <img src="/images/techStack.png" />
                </div>
                {/* <br></br> */}
                <div>
                    <img src="/images/domain.png" />
                </div>
                <br></br>
            </div>
        </>
    );
}



function Leveldetails(inDetail) {
    if (inDetail.hasSubLevel == 0) {
        return '';
    } else if (inDetail.inDetail.hasSubLevel == 1) {
        return (
            <>
                <ul className='listtype1'>
                    {inDetail.inDetail.level1.map(function (object, i) {
                        return <li>{object}</li>;
                    })}
                </ul>
            </>
        )
    } else if (inDetail.inDetail.hasSubLevel == 2) {
        return (
            <>
                <ul className='listtype2'>
                    {inDetail.inDetail.level2.map(function (object, i) {
                        return (
                            <>
                                <li>
                                    <h3>{object.subHeader}</h3>
                                    <ul className='listtype1'>
                                        {object.items.map(function (item, i) {
                                            return <li>{item}</li>;
                                        })}
                                    </ul>
                                </li>
                                {/* <br></br> */}
                            </>
                        )
                    })}
                </ul>
            </>
        )
    }
}

export default Expertise;
