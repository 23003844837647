import React from 'react';
import { NavLink, Link } from "react-router-dom";
function Section2() {
    return (
        <>
            <section className="group-3-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <div className="group-3-2">
                                <img className="shape" src="images/shape.png" alt="" />
                                <div className="text-4">
                                    <p className="text-5">Our Vision</p>
                                    <p className="text-6">
                                        To be a globally acclaimed software development company known for reliable support system, customer satisfaction responsiveness,
                                        constantly strive to excellence with value added solutions, state of the art technology and cost effective solutions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="group-4">
                <div className="container">
                    <div className="row auto-clear match-height-bootstrap-row">
                        <div className="col-md-4 col-xs-12">
                            <div className="group-17">
                                <div className="rectangle-8-copy-3-3"></div>
                                <p className="text-13">
                                    Business Analysis:
                                </p>
                                <p className="text-14">
                                    At Thought Grains, we believe our success is directly tied to providing innovative, out-of-the-box solutions that meet the unique needs
                                    of our partners.
                                </p>
                                <div className="row-3-2 clearfix">
                                    <Link to="/expertise#expert1"><p className="text-15">Read More</p>
                                        <img className="right-arrows-3" src="images/right-arrows_3.png" alt="" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12">
                            <div className="group-17-copy">
                                <div className="rectangle-8-copy-3-2"></div>
                                <p className="text-10">
                                    Project Development
                                </p>
                                <p className="text-11">
                                    We take pride in the fact that for all our clients, whether we are currently working with them or have already completed projects, they have experienced complete satisfaction with the results we provide.
                                </p>
                                <div className="row-2-2 clearfix">
                                    <Link to="/expertise#expert3"><p className="text-12">Read More</p>
                                        <img className="right-arrows-2" src="images/right-arrows_2.png" alt="" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12">
                            <div className="group-17-copy-2">
                                <div className="rectangle-8-copy-3"></div>
                                <p className="text-7">
                                    Quality Management
                                </p>
                                <p className="text-8">
                                    We have a very good quality management team headed by people with immense knowledge in this area.
                                </p>
                                <div className="row-6 clearfix">
                                    <Link to="/expertise#expert2"><p className="text-9">Read More</p>
                                        <img className="right-arrows" src="images/right-arrows.png" alt="" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Section2;