import './../App.css';

function AboutUs() {

    return (
        <>
            <div className="container">
                <div>
                <br></br>
                    <h1 className='text-center aboutUs'>About Thought Grains Solutions</h1>
                    <br></br>
                    <p className='text-justify'>
                        Thought Grains Solutions was founded in 2013 by a group of dedicated professionals and young entrepreneurs who strive for excellence in all their endeavors. As a professional software development and service company, Thought Grains specializes in providing a comprehensive range of services, including Software Development, Web Design, e-commerce Development, and Mobile Application Development.
                    </p>
                    <br></br>
                    <p className='text-justify'>
                        Our core business objective is to deliver superior Managed IT Services and Solutions tailored specifically for Small and Medium Enterprises (SMEs) as well as corporate clients. We are committed to achieving 100% customer satisfaction, guaranteed through our professional service and friendly support.
                    </p>
                    <br></br>
                    <p className='text-justify'>
                        At Thought Grains, we understand that each project is unique, and we pride ourselves on our ability to adapt our services to meet the specific needs of our clients. Whether your company requires Offshore Application Development, Web Design, Graphic Design, or Mobile Application Development, we assure you that our partnership will be mutually beneficial. Our team is dedicated to meeting your needs and adhering to the highest standards of quality.
                    </p>
                </div>
                <br></br>
                <br></br>
                <div>
                    <h1 className=' text-center aboutUs '>Our Mission</h1>
                    <p className='text-justify'>
                        Our team has been built creatively, effectively and passionately to offer world class, comprehensive yet cost-effective IT solutions and services to our Global clienteles enabling to face any challenges.
                    </p>
                </div>
                <br></br>
                <br></br>
                <div>
                    <h1 className='text-center aboutUs'>Our Vision</h1>
                    <p className='text-justify'>
                        To be a globally acclaimed software development company known for reliable support system, Customer satisfaction responsiveness, constantly strive to excellence with value added solutions, state of the art technology and cost effective solutions.
                    </p>
                </div>
                <br></br>
                <br></br>
                <div>
                    <img src="/images/teamStructure.png" loading="lazy" />
                </div>
                <br></br>
                <div>
                    <img src="/images/infrastructure.png" loading="lazy" />
                </div>
            </div>
        </>
    );
}

export default AboutUs;
